import React from 'react';
import Layout from '../components/layout/Layout';
import { graphql } from "gatsby";

const PrivacyPolicy = ({
  location,
}) => (
  <Layout location={location}>
    <div className="container mx-auto px-5 max-w-2xl">
      <div className="py-10 text-3xl text-center">MENTIONS LEGALES</div>

      <div className="py-5 text-gray-600 italic">En vigueur au 15 avril 2022</div>
      
      <div className="py-2 text-gray-800">
        Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du Site https://cephalomax.com les présentes mentions légales.
      </div>
      <div className="py-2 text-gray-800">
        La connexion et la navigation sur le Site https://cephalomax.com par l’Utilisateur impliquent acceptation intégrale et sans réserve des présentes mentions légales.
      </div>
      <div className="py-2 text-gray-800">
        Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
      </div>

      <div className="py-5 text-xl text-primary">
        Article 1 : L’éditeur
      </div>
      <div className="py-2 text-gray-800">
        L’édition du Site https://cephalomax.com est assurée par la Société CEPHALOMAX, Société par Actions Simplifiées au capital de cinq mille euros (5,000€), immatriculée au RCS de Paris sous le numéro B 890 813 975 et ayant pour numéro SIRET 890 813 975 000 12.
        <br/>
        Son siège social est situé 44 RUE BRUNEL 75017 PARIS.
        <br/>
        Adresse de contact : <a href="mailto:contact@cephalomax.com">contact@cephalomax.com</a>
        <br/>
        N° de TVA intracommunautaire : FR78890813975.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 2 : L’hébergeur
      </div>
      <div className="py-2 text-gray-800">
        L’hébergeur du Site https://cephalomax.com est la Société GOOGLE LLC.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 3 : Accès au Site
      </div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com est accessible à tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
      </div>
      <div className="py-2 text-gray-800">
        En cas de modification, interruption ou suspension des services, le Site ne saurait être tenu responsable.
      </div>
      
      <div className="py-5 text-xl text-primary">
        Article 4 : Collecte des données
      </div>
      <div className="py-2 text-gray-800">
        Le Site assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le respect de la vie privée conformément au RGPD (Règlement UE 2016/679 du Parlement européen et du Conseil du 27 avril 2016) et à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.
      </div>
      <div className="py-2 text-gray-800">
        Le Site collecte uniquement les adresses électroniques des Utilisateurs qui souhaitent la fournir pour recevoir des newsletters, des informations ou pour être recontacté dans le cadre de l’activité du Site.
      </div>
      <div className="py-2 text-gray-800">
        En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978 et de l’article 16 du Règlement général sur la protection des données (RGPD), l’Utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition de ses données personnelles. L’Utilisateur exerce ce droit via l’adresse <a href="mailto:contact@cephalomax.com">contact@cephalomax.com</a>.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 5 : Cookies
      </div>
      <div className="py-2 text-gray-800">
        L’Utilisateur est informé que lors de ses visites sur le Site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
      </div>
      <div className="py-2 text-gray-800">
        En naviguant sur le Site https://cephalomax.com il les accepte.
      </div>
      <div className="py-2 text-gray-800">
        Voir plus en détail l’utilisation des cookies au sein de notre « Politique de confidentialité ».
      </div>

      <div className="py-5 text-xl text-primary">
        Article 6 : Propriété intellectuelle
      </div>
      <div className="py-2 text-gray-800">
        Le Site Web est protégé par les droits de propriété intellectuelle et est la propriété exclusive de CEPHALOMAX. L’ensemble des éléments composant le Site (y compris : la conception, le graphisme, le contenu, l’organisation de ce site et sa charte graphique) sont des œuvres originales et sont l’entière propriété de CEPHALOMAX.
      </div>
      <div className="py-2 text-gray-800">
        Toute reproduction, ou démarquage, total ou partiel, fait sans le consentement express de l’auteur est illicite. Il en est de même pour la traduction, l’adaptation, l’arrangement par quelque procédé que ce soit.
        <br/>
        L’ensemble des fonds documentaires, textes, ouvrages et illustrations mis en ligne par l’Éditeur sont protégés par le droit d’auteur et par le droit protégeant les bases de données dont l’Éditeur est producteur au sens des articles L. 341-1 et suivants du Code de la propriété intellectuelle.
      </div>
      <div className="py-2 text-gray-800">
        Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient est considérée comme constitutive d’une contrefaçon et passible de poursuites.
      </div>
      <div className="py-2 text-gray-800">
        Les marques et logos reproduits sur le site sont déposés par les sociétés qui en sont propriétaires.
      </div>

      <div className="py-5 text-xl text-primary">
        Sources et références :
      </div>
      <div className="py-2 text-gray-800">
        Articles L111-1 et suivants du Code de la Propriété Intellectuelle du 1er juillet 1992 ;
        <br/>
        Article 41 de la loi du 11 mars 1957 ;
        <br/>
        Article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995 ;
        <br/>
        Articles L.335-2 et suivants du Code de Propriété Intellectuelle ;
        <br/>
        Loi n° 78-87 du 6 janvier 1978, modifiée par la loi n° 2004-801 du 6 août 2004, relative à l’informatique, aux fichiers et aux libertés ;
        <br/>
        Articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés ;
        <br/>
        Loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données ;
        <br/>
        Loi n° 2004-801 du 6 août 2004 ;
        <br/>
        Article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.
      </div>
    </div>
    
  </Layout>
);

export default PrivacyPolicy;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`